import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Welcome to nexFactory"
        subtitle="Revolutionize your industrial automation with the next generation of control and monitoring software. Join the future of intelligent manufacturing."
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonText="Explore Now"
        buttonColor="primary"
        buttonPath="/about"
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Unmatched Features"
        subtitle="Empower your operations with nexFactory's cutting-edge tools. Designed to streamline your processes and accelerate your innovation."
      />
    </>
  );
}

export default IndexPage;